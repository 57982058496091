<template>
  <div id="app">
    <div class="bg-black p-3">
      <b-row>
        <b-col>
          <h1 class="text-left text-gray-200">Concepts synchronization tool</h1>
        </b-col>
        <b-col>
          <b-link class="btn mr-2" :class="$route.path === '/' ? 'btn-secondary' : 'btn-primary'" :active="$route.path === '/'" to="/">Dealer Portal</b-link>
          <b-link class="btn" :class="$route.path === '/direct' ? 'btn-secondary' : 'btn-primary'" :active="$route.path === '/direct'" to="/direct">Bobcat Direct</b-link>
        </b-col>
        <b-col>
          <h3 class="text-gray text-small">Bookmarklets:</h3>
          <b-button size="sm" variant="primary" href="javascript: window.vm.$store.commit('mymachine/saveShowConcepts', true); window.vm.$store.commit('support/saveShowConcepts', true); window.vm.$store.commit('software/saveShowConcepts', true); window.vm.$store.commit('servicelibrary/saveShowConcepts', true); window.vm.$store.commit('saveShowConcepts', true); window.vm1.$store.commit('saveShowConcepts', true); window.vm2.$store.commit('saveShowConcepts', true); window.vm3.$store.commit('saveShowConcepts', true);">Show concept names</b-button>
          <b-button size="sm" variant="primary" class="ml-2" href="javascript: window.vm.$store.commit('mymachine/saveShowConcepts', false); window.vm.$store.commit('support/saveShowConcepts', false); window.vm.$store.commit('software/saveShowConcepts', false); window.vm.$store.commit('servicelibrary/saveShowConcepts', false); window.vm.$store.commit('saveShowConcepts', false); window.vm1.$store.commit('saveShowConcepts', false); window.vm2.$store.commit('saveShowConcepts', false); window.vm3.$store.commit('saveShowConcepts', false);">Hide concept names</b-button>
        </b-col>
      </b-row>
    </div>

    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style lang="scss">
@import 'scss/colors';

.teatree {
  cursor: pointer;
  height: 100%;
  overflow: hidden;
  > div {
    padding-right: 0.25rem;
  }
}

.teatree-node-item {
  display: flex;
  align-items: center;
  height: 3rem;
  background: transparent;
  .btn {
    margin-left: 2rem;
  }
}

.teatree-node-item-name-padded-leaf {
  padding-left: 1.25rem;
}

.teatree-node-item-icon {
  position: absolute;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  color: $gray-900;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}
.col-items {
    text-align: center;
    max-width: 15rem!important;
}
.col-missing {
  text-align: right;
  max-width: 13rem!important;
}
.col-dropdown {
  max-width: 5rem!important;
}
</style>
